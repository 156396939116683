.single_course_item{
    padding: 15px;
}

.course_img img{
    border-radius: 10px;
    margin-bottom: 20px;
}

.enroll{
    text-decoration: none;
    font-weight: 600;
    font-size: .9rem;
    color: #17bf9e;
}
.lesson, .student, .ratings{
    font-size: .9rem;
    color: #0a2b1e;
    font-weight: 500;
}

.lesson i, .student i, .ratings i {
    font-weight: 400;
}
.course_title{
    line-height: 2rem;
}