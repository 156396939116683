.header{
    width: 100%;
    height: 80px;
    line-height: 80px;
}
.d-flex{
    align-items: center;
}
.nav_list{
    margin: 0;
    padding: 0;
    display: flex;
    column-gap: 1.3rem;
}
.nav_item a{
    font-weight: 500;
    font-size: 1.1rem;
    color: #0a2b1e;
    text-decoration: none;
    transition: 0.3s;
}
.nav_item a:hover {
    color: #17bf9e;
}
.nav_right p{
    font-weight: 500;
    color: #0a2b1e;
    font-size: 1.1rem;
}
.mobile_menu span i{
    font-size: 1.4rem;
    color: #0a2b1e;
}
.mobile_menu{
    display: none;
}

@media screen and (max-width: 991px) {
    
    .nav_menu{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #0a2b1eb2;
        z-index: 99;
        display: none;
    }

    .nav_list{
        position: absolute;
        background: #fff;
        top: 0;
        right: 0;
        width: 250px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 999;
        padding-top: 80px;
    }

    .mobile_menu{
        display: block;
    }
    .nav_right {
        display: none;
    }
    .active_menu{
        display: block;
    }
}

