

.free_course_img{
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;left: 0;
    z-index: 9999;
}

.free_btn{
    position: absolute;
    bottom: -20px;
    right: 20px;
    z-index: 999999;
    padding: 5px 15px;
    font-size: .9rem;
}

.free_course_img img{
    border-radius: 10px;
}

.free_course_details h6{
    font-weight: 600;
    line-height: 2rem;
}
