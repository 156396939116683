body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

/* =========BASE STYLE =======*/

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

section,
footer{
  padding-top: 70px;
  padding-bottom: 70px;
}
h1,
h2,
h3,
h4,
h5,
h6{
  font-size: 2rem;
  line-height: 50px;
  font-family: "Poppins", sans-serif;
  color: #0a2b1e;
  font-weight: 600;
}

p{
  color: #596b65;
  font-size: 1rem;
  line-height: 35px;
  font-family: "Andanda Pro", serif;
  font-weight: 400;
}

.btn {
  background: #17bf9e;
  color: #fff;
  padding: 7px 15px;
  border-radius: 50px;
}

i {
  color: #17bf9e;
}